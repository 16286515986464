/* eslint-disable no-undef */
class TokenService {
  getLocalAccessToken() {
    const user = $cookies.get("token");

    return user;
  }
  updateLocalAccessToken(token) {
    $cookies.set("token", token);
    return token;
  }
  setUser(user) {
    $cookies.set("user", user);
    return user;
  }
  setToken(token) {
    $cookies.set("token", token);
    return token;
  }
  removeUser() {
    $cookies.remove("user");

    return true;
  }
  removeToken() {
    $cookies.remove("token");

    return true;
  }
}

export default new TokenService();
