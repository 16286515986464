<template>
  <v-app>
    <v-main class="auth-pages">
      <div
        class="header-auth position-relative ma-4 pb-16 pt-16 border-radius-xl"
      ></div>

      <v-container class="mt-n16 pb-0">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "page-layout",
  components: {},
  data() {
    return {
      paragraphs: "",
      tab: null,
    };
  },
  methods: {
    headerTitle() {
      switch (this.$route.name) {
        case "SignUpBasic":
          this.paragraphs =
            "Use these awesome forms to login or create new account in your project for free.";
          return "Welcome!";
        default:
          break;
      }
    },
  },
};
</script>
