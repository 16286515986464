/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@fortawesome/fontawesome-free/css/all.css";
// import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);
export default new Vuetify({
  rtl: false,
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#00BFEF",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c",
      },
    },
  },
  icons: {
    iconfont: "fa",
  },
});
