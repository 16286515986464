<template>
  <v-navigation-drawer
    width="100%"
    fixed
    app
    floating
    :expand-on-hover="mini"
    :value="drawer"
    :right="$vuetify.rtl"
    class="my-4 ms-4 border-radius-lg"
    :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'"
    :data-color="sidebarColor"
    :data-theme="sidebarTheme"
  >
    <v-list nav dense>
      <v-list-item class="pa-0">
        <v-list-item-content class="pa-0">
          <v-list-item-title class="title d-flex align-center mb-0">
            <div class="v-navigation-drawer-brand pa-5 d-flex align-center">
              <v-img id="logo-teca" :src="getLogo" max-width="210PX" />
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item-group>
        <div v-for="(item, i) in items" :key="i">
          <v-list-group
            class="ml-2"
            v-if="item.items"
            :value="false"
            :prepend-icon="item.icon"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>
            <div v-for="(item, i) in item.items" :key="i">
              <v-list-item
                link
                :to="item.link"
                class="pb-1 mx-2 no-default-hover"
                :ripple="false"
                active-class="item-active"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.title"
                    class="ms-1"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list-group>
          <v-list-item
            v-else
            link
            :to="item.link"
            class="pb-1 mx-2 no-default-hover"
            :ripple="false"
            active-class="item-active"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.title"
                class="ms-1"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "transparent",
    },
  },
  data: () => ({
    mini: false,
    togglerActive: false,
    thirdLevelSimple: [
      "Third level menu",
      "Just another link",
      "One last link",
    ],
    items: [
      {
        icon: "fa-user",
        link: "/pages/users",
        title: "Usuarios",
      },
      {
        icon: "fa-solid fa-chart-line",
        link: "/pages/dashboard",
        title: "Dashboard",
      },
      {
        icon: "fa-microchip",
        title: "Dispositivos",
        items: [
          {
            icon: "fa-list",
            link: "/pages/devices",
            title: "Gestion",
          },
        ],
      },
      {
        icon: "fa-chart-line",
        link: "/pages/reports",
        title: "Reportes",
        items: [
          {
            icon: "fa-map",
            link: "/pages/reports/mapas",
            title: "Mapas",
          },
          {
            icon: "fa-regular fa-clipboard",
            link: "/pages/reports/totales",
            title: "Reportes Totales",
          },
          {
            icon: "fa-solid fa-exclamation",
            link: "/pages/reports/Alertas",
            title: "Alarmas",
          },
          {
            icon: "fa-solid fa-bell",
            link: "/pages/reports/CustomAlertas",
            title: "Alertas",
          },
        ],
      },
    ],
  }),
  computed: {
    getLogo() {
      return this.$cookies.get("logo");
    },
  },
  mounted() {
    this.cambiarColor();
    this.Agregardrawer();
  },
  methods: {
    async Agregardrawer() {
      if (this.$cookies.get("agua") === "true") {
        this.items[3].items.push({
          icon: "fa-water",
          link: "/pages/reports/agua",
          title: "Agua",
        });
      }

      if (this.$cookies.get("gas") === "true") {
        this.items[3].items.push({
          icon: "fa-fire",
          link: "/pages/reports/gas",
          title: "Gas",
        });
      }

      if (this.$cookies.get("energia") === "true") {
        this.items[3].items.push({
          icon: "fa-bolt",
          link: "/pages/reports/energia",
          title: "Energía",
        });
      }
      await this.getItems();
    },
    cambiarColor() {
      this.$vuetify.theme.themes.light.primary = this.$cookies.get("color");
    },
    getItems() {
      if (
        this.$cookies.get("isAdmin") === "false" &&
        this.$cookies.get("isSuperAdmin") === "false"
      ) {
        this.items.splice(0, 3);
      }

      if (
        this.$cookies.get("isAdmin") === "true" &&
        this.$cookies.get("isSuperAdmin") === "false"
      ) {
        this.items.splice(1, 1);
      }

      if (this.$cookies.get("isSuperAdmin") === "true") {
        this.items.splice(2, 3);
      }
      if (this.$cookies.get("user").profileId === 4) {
        this.items[2].items.splice(1, 1);
      }
      //
      // return this.$cookies.get("isAdmin");
    },
  },
};
</script>
