import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import * as VueGoogleMaps from "gmap-vue";
import VueCookies from "vue-cookies";
import setupInterceptors from "@/services/setupInterceptors";
import VueGeolocation from "vue-browser-geolocation";
import VueApexCharts from "vue-apexcharts";
import store from "./store";

Vue.config.productionTip = false;

setupInterceptors();

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(Photoswipe);
Vue.use(VueGeolocation);

Vue.use(vuetify);
Vue.use(VueCookies);

// plugin setup
Vue.use(DashboardPlugin);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAn_bqJvKg6qI2gcbZ5N6RpjiuyiiZKUAo",
  },
  installComponents: true,
});

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
