import Vue from "vue";
import VueRouter from "vue-router";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout.vue";
// import ErrorLayout from "../views/Layout/ErrorLayout.vue";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import BackendApi from "@/services/backend.service";

const Login = () => import("../views/Pages/Authentication/Login.vue");
const Users = () => import("../views/Pages/Users/Users.vue");
const UserN2 = () => import("../views/Pages/Users/UsersN2.vue");
const Dashboard = () => import("../views/Pages/Dashboard/Dashboard.vue");
const Devices = () => import("../views/Pages/Devices/Devices.vue");
const DevicesCallibrar = () => import("../views/Pages/Devices/Calibrar.vue");
const TotalReports = () => import("../views/Pages/Reports/TotalReport.vue");
const AlertDeviceData = () =>
  import("../views/Pages/Reports/AlertDeviceData.vue");
const CustomAlert = () => import("../views/Pages/Reports/CustomAlert.vue");
const Water = () => import("../views/Pages/Reports/Water.vue");
const Energy = () => import("../views/Pages/Reports/Energy.vue");
const Gas = () => import("../views/Pages/Reports/Gas.vue");
const Maps = () => import("../views/Pages/Reports/Maps.vue");

Vue.use(VueRouter);

let loginPage = {
  path: "/",
  component: AuthBasicLayout,
  name: "login",
  redirect: { name: 'Inicio de sesión' },
  children: [
    {
      path: "/login",
      name: "Inicio de sesión",
      component: Login,
    },
  ],
};

let NotFound = {
  path: "*",
  name: "404",
  component: () =>
    import(
      /* webpackChunkName: "404" */ "@/views/Pages/error/NotFoundPage.vue"
    ),
};

// let NotFound = {
//   path: "*",
//   name: "404",
//   component: ErrorLayout,
//   children: [
//     {
//       path: "/a",
//       name: "not found",
//       component: () =>
//         import(
//           /* webpackChunkName: "404" */ "@/views/Pages/error/NotFoundPage.vue"
//         ),
//     },
//   ],
// };

// {/* <i class="fa-solid fa-check"></i>; */}
// {/* <i class="fa-solid fa-scale-balanced"></i> */}
// {/* <i class="fa-solid fa-scale-unbalanced-flip"></i>; */}
let usersPage = {
  path: "/",
  component: DashboardLayout,
  name: "Marco",
  children: [
    {
      path: "/pages/users",
      name: "Gestión de usuarios",
      component: Users,
    },
    {
      path: "/pages/users/userN2/:id",
      name: "UsuariosN2",
      component: UserN2,
    },
    {
      path: "/pages/dashboard",
      name: "Dashboard",
      component: Dashboard,
    },
    {
      path: "/pages/devices",
      name: "Gestión de dispositivos",
      component: Devices,
    },
    {
      path: "/pages/device/calibrar",
      name: "Calibrar",
      component: DevicesCallibrar,
    },
    {
      path: "/pages/reports/totales",
      name: "Reportes Totales",
      component: TotalReports,
    },
    {
      path: "/pages/reports/Alertas",
      name: "Alarma de dispositivos",
      component: AlertDeviceData,
    },
    {
      path: "/pages/reports/CustomAlertas",
      name: "Alerta",
      component: CustomAlert,
    },
    {
      path: "/pages/reports/agua",
      name: "Reportes Agua",
      component: Water,
    },
    {
      path: "/pages/reports/energia",
      name: "Reportes Energia",
      component: Energy,
    },
    {
      path: "/pages/reports/gas",
      name: "Reportes Gas",
      component: Gas,
    },
    {
      path: "/pages/reports/mapas",
      name: "Reportes Mapa",
      component: Maps,
    },
  ],
};

const routes = [loginPage, usersPage, NotFound];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  // if (from.fullPath === "/") {
  //   let isAuthenticated = false;

  //   BackendApi.post("/auth/refresh")
  //     .then((response) => {
  //       isAuthenticated = response.data.success;
  //       if (isAuthenticated) {
  //         return next({ name: "Gestión de usuarios" });
  //       } else {
  //         return next({ name: "Inicio de sesión" });
  //       }
  //     })
  //     .catch((error) => {
  //       
  //       return next({ name: "Inicio de sesión" });
  //     });
  // }

  if (to.name === "Inicio de sesión") {
    return next();
  } else {
    let isAuthenticated = false;

    BackendApi.post("/auth/refresh")
      .then((response) => {
        isAuthenticated = response.data.success;
        if (isAuthenticated) {
          return next();
        } else {
          return next({ name: "Inicio de sesión" });
        }
      })
      .catch((error) => {
        

        return next({ name: "Inicio de sesión" });
      });
  }
});

export default router;
