export default {
  state: {
    periodoDeTransmision: [
      {
        key: "FF",
        value: "1 Mensaje de registro cada 24 Hrs",
      },
      {
        key: "EE",
        value: "1 Mensaje de registro cada 12 Hrs",
      },
      {
        key: "DD",
        value: "1 Mensaje de registro cada 6 Hrs",
      },
      {
        key: "CC",
        value: "1 Mensaje de registro cada 3 Hrs",
      },
      {
        key: "BB",
        value: "1 Mensaje de registro cada 1 Hrs",
      },
      {
        key: "AA",
        value: "1 Mensaje de registro cada 10 min",
      },
      {
        key: "99",
        value: "1 Mensaje de registro cada 5 min",
      },
    ],
    mensualDownlink: [
      {
        key: "0",
        value: "No mensual downlink",
      },
      {
        key: "1",
        value: "1 downlink cada 30 dias",
      },
      {
        key: "2",
        value: "1 downlink cada 15 dias",
      },
      {
        key: "3",
        value: "1 downlink cada 7.5 dias",
      },
      {
        key: "4",
        value: "1 downlink cada 3.75 dias",
      },
      {
        key: "5",
        value: "1 downlink cada 1.87 dias",
      },
      {
        key: "6",
        value: "1 downlink cada 0.93 dias",
      },
      {
        key: "7",
        value: "1 downlink cada 11 hrs",
      },
      {
        key: "8",
        value: "1 downlink cada 4.8 hrs",
      },
      {
        key: "9",
        value: "1 downlink cada 10 min",
      },
    ],
    opcionesDeDepuracion: [
      {
        key: "0",
        value: "No debug",
      },
      {
        key: "1",
        value: "pulso led debug",
      },
      {
        key: "2",
        value: "debug serial",
      },
      {
        key: "3",
        value: "pulso led+debug serial",
      },
      {
        key: "4",
        value: "Modulación portadora continua CW",
      },
    ],
    localUplink: [
      {
        key: "0",
        value: "No adicionar local uplink",
      },
      {
        key: "F",
        value: "Adicionar local Uplink (LU_3) a la transmisión",
      },
    ],
    accion: [
      {
        key: "00",
        value: "CERRAR",
      },
      {
        key: "FF",
        value: "ABRIR",
      },
    ],
    forzarAccion: [
      {
        key: "0",
        value: "automático",
      },
      {
        key: "F",
        value: "forzar",
      },
    ],
  },
  getters: {
    getInputs: (state) => {
      const primerFormato = [
        {
          name: "Periodo de transmisión",
          valueSelected: "FF",
          values: [...state.periodoDeTransmision],
          state: true,
        },
        {
          name: "Mensual Downlink",
          valueSelected: "0",
          values: [...state.mensualDownlink],
          state: true,
        },
        {
          name: "no def",
          valueSelected: "00",
          values: [],
          state: false,
        },
        {
          name: "Opciones de depuración",
          valueSelected: "0",
          values: [...state.opcionesDeDepuracion],
          state: true,
        },
        {
          name: "no def",
          valueSelected: "0",
          values: [],
          state: false,
        },
        {
          name: "Local Uplink",
          valueSelected: "0",
          values: [...state.localUplink],
          state: true,
        },
        {
          name: "no def",
          valueSelected: "0",
          values: [],
          state: false,
        },
        {
          name: "no def",
          valueSelected: "0",
          values: [],
          state: false,
        },
        {
          name: "no def",
          valueSelected: "0",
          values: [],
          state: false,
        },
        {
          name: "no def",
          valueSelected: "0",
          values: [],
          state: false,
        },
        {
          name: "no def",
          valueSelected: "0",
          values: [],
          state: false,
        },
        {
          name: "no def",
          valueSelected: "0",
          values: [],
          state: false,
        },
        {
          name: "no def",
          valueSelected: "0",
          values: [],
          state: false,
        },
        {
          name: "UPDATE",
          valueSelected: "0",
          values: [],
          state: false,
        },
      ];

      const segundoFormato = [
        {
          name: "Periodo de transmisión",
          valueSelected: "FF",
          values: [...state.periodoDeTransmision],
          state: true,
        },
        {
          name: "no def",
          valueSelected: "0",
          values: [],
          state: false,
        },
        {
          name: "Acción",
          valueSelected: "00",
          values: [...state.accion],
          state: true,
        },
        {
          name: "Opciones de depuración",
          valueSelected: "0",
          values: [...state.opcionesDeDepuracion],
          state: true,
        },
        {
          name: "Forzar acción",
          valueSelected: "0",
          values: [...state.forzarAccion],
          state: true,
        },
        {
          name: "Local Uplink",
          valueSelected: "0",
          values: [...state.localUplink],
          state: true,
        },
        {
          name: "no def",
          valueSelected: "0",
          values: [],
          state: false,
        },
        {
          name: "no def",
          valueSelected: "0",
          values: [],
          state: false,
        },
        {
          name: "no def",
          valueSelected: "0",
          values: [],
          state: false,
        },
        {
          name: "no def",
          valueSelected: "0",
          values: [],
          state: false,
        },
        {
          name: "no def",
          valueSelected: "0",
          values: [],
          state: false,
        },
        {
          name: "no def",
          valueSelected: "0",
          values: [],
          state: false,
        },
        {
          name: "no def",
          valueSelected: "0",
          values: [],
          state: false,
        },
        {
          name: "UPDATE",
          valueSelected: "0",
          values: [],
          state: false,
        },
      ];

      const inputs = [
        {
          id: 1,
          name: "",
          value: [...primerFormato],
        },
        {
          id: 2,
          name: "",
          value: [...primerFormato],
        },
        {
          id: 3,
          name: "",
          value: [...segundoFormato],
        },
        {
          id: 4,
          name: "",
          value: [...segundoFormato],
        },
        {
          id: 5,
          name: "",
          value: [...segundoFormato],
        },
        {
          id: 6,
          name: "",
          value: [...primerFormato],
        },
        {
          id: 7,
          name: "",
          value: [...primerFormato],
        },
        {
          id: 8,
          name: "",
          value: [...segundoFormato],
        },
        {
          id: 9,
          name: "",
          value: [...segundoFormato],
        },
        {
          id: 10,
          name: "",
          value: [...segundoFormato],
        },
        {
          id: 11,
          name: "",
          value: [...primerFormato],
        },
        {
          id: 12,
          name: "",
          value: [...segundoFormato],
        },
        {
          id: 13,
          name: "",
          value: [...segundoFormato],
        },
      ];

      return [...inputs];
    },
  },
  mutations: {
    // setFile(state, payload) {
    //   state.file = payload;
    // },
    // setName(state, payload) {
    //   state.name = payload;
    // },
  },
  actions: {
    // fileData({ commit }, data) {
    //   commit("setFile", data);
    // },
    // name({ commit }, data) {
    //   commit("setName", data);
    // },
  },
};
