export default {
  state: {
    years: [
      "2019",
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
      "2041",
      "2042",
      "2043",
      "2044",
      "2045",
      "2046",
      "2047",
      "2048",
      "2049",
      "2050",
    ],
    months: [
      {
        id: 1,
        value: "Enero",
      },
      {
        id: 2,
        value: "Febrero",
      },
      {
        id: 3,
        value: "Marzo",
      },
      {
        id: 4,
        value: "Abril",
      },
      {
        id: 5,
        value: "Mayo",
      },
      {
        id: 6,
        value: "Junio",
      },
      {
        id: 7,
        value: "Julio",
      },
      {
        id: 8,
        value: "Agosto",
      },
      {
        id: 9,
        value: "Septiembre",
      },
      {
        id: 10,
        value: "Octubre",
      },
      {
        id: 11,
        value: "Noviembre",
      },
      {
        id: 12,
        value: "Diciembre",
      },
    ],
    typeServices: [
      {
        id: 4,
        value: "Todos",
      },
      {
        id: 1,
        value: "Medicion",
      },
      {
        id: 2,
        value: "Telecontrol",
      },
      {
        id: 3,
        value: "Medicion + Telecontrol",
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
};
